<template>
  <div>
    <el-dialog width="80%" v-model="dialogSend" title="拖动排序" :close-on-click-modal="false" class="button_bg">
        <el-table height="500px"  class="table_height2"
        stripe
        ref="dragTable"
        row-key="id" size="mini" 
        :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" 
        v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;">
          <el-table-column label="日期" sortable="true" width="100" align="center">
            <template #default="scope">
              <span>{{ $parseTime(scope.row.voucherDate, "{y}-{m}-{d}")}}</span>
            </template>
          </el-table-column>
          <el-table-column label="凭证字号"  align="center" min-width="60">
            <template #default="scope">
              <div class="item_icon">
                <i class="iconfont icon-shengchengpingzheng1"></i>
                <span>{{ scope.row.voucherNo }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="摘要" min-width="200" align="left">
            <template #default="scope">
              <span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;display:block;padding: 5px;text-align: left;" v-if="!showList">
                {{scope.row.voucherItem[0].summary}}
              </span>
              <span v-else style="">
                <span v-for="(item,index) in scope.row.voucherItem" :key="index" style="display:block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;padding: 5px;text-align: left;">{{item.summary}}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="科目" min-width="300" align="left"> 
            <template #default="scope">
              <span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;display:block;padding: 5px;text-align: left;" v-for="(item,index) in scope.row.voucherItem" :key="index">
                <el-tooltip class="item"  effect="dark" :content="item.subjectName" placement="top">
                  <span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;display:block;text-align: left;">{{item.subjectName}}</span>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="借方" min-width="90" align="left">
            <template #default="scope">
              <p style="padding: 5px;text-align: left;" v-for="(item,index) in scope.row.voucherItem" :key="index" >{{item.inAmount}}</p>
            </template>
          </el-table-column>
          <el-table-column label="贷方" min-width="90" align="left">
            <template #default="scope">
              <p style="padding: 5px;text-align: left;" v-for="(item,index) in scope.row.voucherItem" :key="index"  >{{item.inAmount}}</p>
            </template>
          </el-table-column>
        </el-table>
      <template #footer>
        <span class="dialog-footer">
          <span style="float: left;">
            <el-radio-group v-model="showList">
              <el-radio :label="false">合并摘要显示</el-radio>
              <el-radio :label="true">正常显示</el-radio>
            </el-radio-group>
          </span>
          <el-button @click="dialogSend = false" size="mini">取 消</el-button>
          <el-button v-loading.fullscreen.lock="fullscreenLoading" type="primary" @click="handleDialogSure" size="mini">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { voucherList ,dragSort } from "@/api/voucher.js";
import { checkCompatEnabled } from "@vue/compiler-core";

import Sortable from 'sortablejs/Sortable';
export default {
  props: {
    type: {
      type: String,
      default: '1'//存货
    },
  },
  filters: {
    // toText(val) {
    //   switch (val) {
    //     case 0:
    //       return "未审核";
    //     case 1:
    //       return "未审核";
    //     case 2:
    //       return "已审核";
    //     default:
    //       return "";
    //   }
    // },
    // tofromtype(val) {
    //   switch (val) {
    //     case "1":
    //       return "进项";
    //     case "2":
    //       return "销项";
    //     case "3":
    //       return "费用";
    //     case "4":
    //       return "现金";
    //     case "5":
    //       return "票据";
    //     case "6":
    //       return "银行";
    //     case "9":
    //       return "计提税金";
    //     case "7":
    //       return "计提工资";
    //     case "8":
    //       return "结转损益";
    //     case "10":
    //       return "资产";
    //     case "合并":
    //       return "合并";
    //     case "12":
    //       return "存货核算";
    //     case "13":
    //       return "暂估回冲";
    //     case "14":
    //       return "暂估入库";
    //     case "100":
    //       return "年末结转利润";
    //     case "20":
    //       return "进项认证";
    //     case "30":
    //       return "固定资产清理";
    //     case "40":
    //       return "汇兑损益";
    //     case "手动合并":
    //       return "手动合并";
    //     default:
    //       return "手工录入";
    //   }
    // }
  },
  data () {
    return {
      showList: false,
      fullscreenLoading: false,
      listQuery: {
        sort: "period,REPLACE(voucher_no,'记','')+0",
        page: 1,
        limit: 50000,
        beginTime: '',
        endTime: '',
        fromType: "",
        voucherNo: "",
        periodF: ""
      },
      dialogSend: false,
      list: [],
    };
  },
  created(){
    // this.setSort()
  },
  mounted() {
  },
  methods: {
    handleDialogSure(){
      this.fullscreenLoading = true
      dragSort(this.newList).then(res=>{
        this.fullscreenLoading = false
        this.getList()
        this.$message({
          message: '排序完成',
          type: 'success'
        });
        this.$emit("successSort")
        this.dialogSend = false
      })
    },
    getList(){
      this.listLoading = true;
      voucherList(this.listQuery).then(response => {
        let list = response.data.data.list;
        this.list = list;
        if(!response.data.data.total){
          this.total = 0
        }else{
          this.total = response.data.data.total;
        }
        let arr = this.list
        this.list = arr
        this.newList = this.list.slice()
        this.setSort()
        this.listLoading = false;
      });
    },
    changesort(e){
      if(e.order == 'descending'){
        this.descending = 'desc'
        this.listQuery.sort = "period,voucher_date desc"
      }else{
        this.descending = ''
        this.listQuery.sort = "period,voucher_date"
      }
      this.getList();
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper tbody')[0]
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost', // Class name for the drop placeholder,
        setData: function(dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: evt => {
          const targetRow = this.list.splice(evt.oldIndex, 1)[0]
          this.list.splice(evt.newIndex, 0, targetRow)
          const tempIndex = this.newList.splice(evt.oldIndex, 1)[0]
          this.newList.splice(evt.newIndex, 0, tempIndex)
          //console.log(this.newList)
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.item {
    margin-bottom: 10px;
  }
  .addcode{
    margin-bottom: 10px;
  }


  // .table_drag{
  //   width: 500px;
  //   tr{
  //     height: 50px;
  //     background: orange;
  //     width: 500px;
  //   }
  // }
</style>


